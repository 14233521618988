import type { LoggedInUser, UserAccount } from '@/services/blackfisk/auth'
import type { Ref } from 'vue'

import { displayUserName } from '@/entities/user'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { authLogin, pinLogin } from '@/services/blackfisk/auth'

// import { useService } from '@/composables/useService'

import { PermissionControls } from '@/modules/permissions'
import { useAuthorizationSync } from '@/composables/useAuthorizationSync'
import { useRequestErrorToast } from '@/composables/useRequestErrorToast'

export const useIdentityStore = defineStore(
  'identity',
  () => {
    // const environment = import.meta.env.VITE_ENVIRONMENT
    // const version = '2.7.23'
    // const user = localStorage.getItem(`bfsk-login-${environment}-${version}`)
    const getToken = () => {
      return localStorage.getItem('token') || ''
    }
    const userId = ref(0)
    const userName = ref('')
    const userEmail = ref('')
    const accountId = ref(0)
    const roles: Ref<string[]> = ref([])
    const accounts: Ref<UserAccount[]> = ref([])
    const token = ref(getToken())
    const isAuthenticated = computed(() => token.value.length > 0)
    const returnUrl: Ref<string> = ref('')
    const updateAccountId = (updatedAccountId: number) => {
      accountId.value = updatedAccountId
    }
    const activeAccount = computed(() => {
      const accountMap = accounts.value.reduce<Record<string, UserAccount>>(
        (accumulator, account) => {
          accumulator[`${account.id}`] = account
          return accumulator
        },
        {}
      )
      return accountMap[accountId.value]
    })
    const userAccountIds = computed(() =>
      accounts.value.map((account) => account.id)
    )

    const { broadcastLogin, broadcastLogout } = useAuthorizationSync()
    const parseLoggedInUser = (user: LoggedInUser) => {
      if (user.token) {
        localStorage.setItem('token', user.token)
        token.value = user.token
      }
      userName.value = displayUserName(user)
      userEmail.value = user.email
      if ((user?.masterAccountId ?? 0) > 0) {
        accountId.value = user.masterAccountId
      }
      if ((user?.accounts ?? []).length > 0) {
        accounts.value = user.accounts.map((account) => ({
          id: account.id,
          name: account.name,
          ianaTimeZoneString: account.ianaTimeZoneString,
        }))
      }
      if ((user?.roles ?? []).length > 0) {
        roles.value = user.roles.map((role) => role.role)
      }
      // * Set ID last so data is available for any watchers.
      userId.value = user.id
    }
    const quickLogin = async ({ userId = 0, pin = 0, checksum = '' } = {}) => {
      const { user = null, errors = [] } = await pinLogin({
        userId,
        pin,
        checksum,
      })
      if (user && (user?.id ?? 0) > 0) {
        broadcastLogin(user)
        parseLoggedInUser(user)
      } else {
        useRequestErrorToast(errors, 'Error Signing In')
      }
    }
    const login = async ({ email = '', password = '' } = {}) => {
      const { user, errors } = await authLogin({
        email,
        password,
      })
      if (user && (user?.id ?? 0) > 0) {
        broadcastLogin(user)
        parseLoggedInUser(user)
      } else {
        useRequestErrorToast(errors, 'Error Signing In')
      }
    }
    const logout = async ({ broadcast = true } = {}) => {
      userName.value = ''
      userEmail.value = ''
      accountId.value = 0
      roles.value = []
      accounts.value = []
      token.value = ''
      localStorage.setItem('token', '')
      // * Set ID last so data is available for any watchers.
      userId.value = 0
      if (broadcast) {
        broadcastLogout()
      }
    }
    const hasPermission = (
      {
        requiredRoles = [],
        requiredGroups = [],
      }: {
        requiredRoles: (keyof (typeof PermissionControls)['roles'])[]
        requiredGroups: (keyof (typeof PermissionControls)['groups'])[]
      } = {
        requiredRoles: [],
        requiredGroups: [],
      }
    ) => {
      if (requiredGroups.length === 0 && requiredRoles.length === 0) {
        return true
      }
      for (const userRole of roles.value) {
        for (const group of requiredGroups) {
          const hasRole = (PermissionControls?.groups?.[group] ?? []).includes(
            userRole
          )
          if (hasRole) {
            return true
          }
        }
        for (const role of requiredRoles) {
          const hasRole = (PermissionControls?.roles?.[role] ?? []).includes(
            userRole
          )
          if (hasRole) {
            return true
          }
        }
      }
      return false
    }
    const hasMasterAccess = computed(() => {
      return hasPermission({
        requiredRoles: [],
        requiredGroups: ['masterAccess'],
      })
    })

    return {
      userAccountIds,
      updateAccountId,
      accountId,
      userId,
      userName,
      userEmail,
      roles,
      accounts,
      token,
      login,
      quickLogin,
      parseLoggedInUser,
      logout,
      isAuthenticated,
      hasPermission,
      returnUrl,
      activeAccount,
      hasMasterAccess,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
