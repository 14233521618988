import type {
  Paper,
  PaperType,
  Printer,
  PrinterInsert,
  PrinterPatch,
} from '@/entities/printer/types/printer'
import type { Server } from '@/entities/server/types/server'
import type { EntityServiceResponseError } from '@/services/blackfisk'

import {
  blackfiskAxiosRequest,
  parseBlackfiskBulkResponse,
} from '@/services/blackfisk'

// # Paper
export interface PaperGraph extends Paper {
  type: PaperType
}
export async function paperList(forceRefresh = false) {
  const { errors, data, cached } = await blackfiskAxiosRequest<PaperGraph[]>(
    {
      method: 'get',
      url: '/blackfisk/warehouse/printers/paper',
      params: {
        ...(forceRefresh && { ts: +new Date() }),
      },
    },
    (response) => {
      return response.data
    }
  )
  return {
    errors,
    papers: data || [],
    cached,
  }
}

// # Printer
export interface PrinterGraph extends Printer {
  paper: PaperGraph
}
export interface ServerGraph extends Server {
  printers: PrinterGraph[]
}

export async function printerList(forceRefresh = false) {
  const { errors, data, cached } = await blackfiskAxiosRequest<ServerGraph[]>(
    {
      method: 'get',
      url: '/blackfisk/warehouse/printers',
      params: {
        ...(forceRefresh && { ts: +new Date() }),
      },
    },
    (response) => {
      return response.data
    }
  )
  return {
    errors,
    servers: data || [],
    cached,
  }
}

export const printerPatchBulk = async (patches: PrinterPatch[]) => {
  if (patches.length > 0) {
    const { errors, data: responseErrors } = await blackfiskAxiosRequest<
      { errors: EntityServiceResponseError[] }[],
      EntityServiceResponseError[]
    >(
      {
        method: 'PATCH',
        url: '/v2/blackfisk/printer/bulk',
        data: {
          patches,
        },
      },
      parseBlackfiskBulkResponse
    )
    return {
      errors: [...errors, ...(responseErrors || [])],
    }
  }
  return {
    errors: [],
  }
}
export const printerInsertBulk = async (inserts: PrinterInsert[]) => {
  if (inserts.length > 0) {
    const { errors, data: responseErrors } = await blackfiskAxiosRequest<
      { errors: EntityServiceResponseError[] }[],
      EntityServiceResponseError[]
    >(
      {
        method: 'PUT',
        url: '/v2/blackfisk/printer/bulk',
        data: {
          inserts,
        },
      },
      parseBlackfiskBulkResponse
    )
    return {
      errors: [...errors, ...(responseErrors || [])],
    }
  }
  return {
    errors: [],
  }
}
export const printProductBarcodeLabel = async (
  serverPrinterId: number,
  barcode: string,
  sku: string,
  title: string
) => {
  const { errors } = await blackfiskAxiosRequest<void>(
    {
      method: 'POST',
      url: '/v1/blackfisk/print/printProductPackageLabel',
      data: {
        serverPrinterId,
        barcode,
        labelSku: sku,
        labelTitle: title,
      },
    },
    (response) => {
      return response.data
    }
  )
  return errors
}
export const printRetailBarcodeLabel = async (
  serverPrinterId: number,
  productId: number,
  channelId: number,
  quantity = 1,
  delayMilliseconds = 0
) => {
  const { errors } = await blackfiskAxiosRequest<void>(
    {
      method: 'POST',
      url: '/v1/blackfisk/print/printRetailProductLabel',
      data: {
        serverPrinterId,
        productId,
        channelId,
        quantity,
        delayMilliseconds,
      },
    },
    (response) => {
      return response.data
    }
  )
  return errors
}
export const printEmployeeBadgeLabel = async (
  serverPrinterId: number,
  userId: number
) => {
  const { errors } = await blackfiskAxiosRequest<void>(
    {
      method: 'POST',
      url: '/v1/blackfisk/print/printEmployeeBarcodeLabel',
      data: {
        serverPrinterId,
        userId,
      },
    },
    (response) => {
      return response.data
    }
  )
  return errors
}
