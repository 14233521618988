export const routes = [
  {
    path: '/transfers/manage',
    name: 'transfer-manager',
    component: () => import('./views/TransferManager.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  {
    path: '/transfers/fba/:id',
    name: 'transfer-fba-editor',
    component: () => import('./views/TransferFBAEditor.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  {
    path: '/transfers/:id',
    name: 'transfer-editor',
    component: () => import('./views/TransferEditor.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  {
    path: '/transfers/fba/:id/pack',
    name: 'transfer-pack-ship',
    component: () => import('./views/TransferPackShip.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
]
