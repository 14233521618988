/// <reference types="w3c-web-hid" />

import { computed, ref, shallowRef } from 'vue'

// ! "Global" state
const scaleDevice = shallowRef<null | HIDDevice>(null)
const isScaleOpened = ref(false)
export interface ScaleWeightData {
  units: 'kg' | 'lb'
  value: number
  state: 'settled' | 'changing'
}
export type OnWeightUpdateSignature = (weightData: ScaleWeightData) => void
export type OnDisconnectSignature = (event: HIDConnectionEvent) => void
export type OnConnectSignature = (event: HIDConnectionEvent) => void
export const useScale = (
  onWeightUpdate: OnWeightUpdateSignature,
  onConnect: OnConnectSignature = (event) => {
    console.log('Connected', { device: event.device })
  },
  onDisconnect: OnDisconnectSignature = (event) => {
    console.log('Disconnected', { device: event.device })
  },
  debug = false
) => {
  if (navigator.hid) {
    navigator.hid.addEventListener('connect', (event) => {
      onConnect(event)
    })
    navigator.hid.addEventListener('disconnect', (event) => {
      onDisconnect(event)
      removeScaleDevice()
    })
  }
  const removeScaleDevice = () => {
    scaleDevice.value = null
  }
  const isScalePaired = computed(() => {
    return scaleDevice.value !== null
  })
  const updateScale = async () => {
    if (navigator.hid) {
      try {
        const devices = await navigator.hid.getDevices()
        if (devices.length) {
          const device = devices.find((device) => device.vendorId === 3768)
          if (device) {
            scaleDevice.value = device
          } else {
            removeScaleDevice()
          }
        } else {
          removeScaleDevice()
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      removeScaleDevice()
    }
  }
  const requestScale = async () => {
    const devices = await navigator.hid.requestDevice({
      filters: [
        { vendorId: 3768 }, // Mettler Toledo
        // { vendorId: 2338 },
        // { vendorId: 1241 },
      ],
    })
    if (devices.length > 0) {
      scaleDevice.value = devices[0]
      return true
    }
    return false
  }
  const connectScale = async () => {
    if (navigator.hid) {
      console.log('Request Scale')
      try {
        const deviceList = await navigator.hid.getDevices()
        // * Reestablish device after reload
        if (deviceList.length === 1) {
          scaleDevice.value = deviceList[0]
          return true
        }
        return false
      } catch (err) {
        console.error(err)
      }
    }
  }

  const openScale = async () => {
    if (scaleDevice.value) {
      try {
        await scaleDevice.value.open()
        isScaleOpened.value = true
      } catch (err) {
        isScaleOpened.value = false
        debug && console.error('Encountered error opening Scale Device', err)
      }
    }
  }
  const closeScale = async () => {
    if (scaleDevice.value) {
      try {
        scaleDevice.value.oninputreport = null
        await scaleDevice.value.close()
        isScaleOpened.value = false
      } catch (err) {
        console.error('Encountered error closing Scale Device', err)
      }
    }
  }
  const initiateRead = async () => {
    if (scaleDevice.value) {
      scaleDevice.value.oninputreport = (ev) => {
        const data = new Uint8Array(ev.data.buffer)
        if (data.length >= 5) {
          // * Mettler Toledo BS60
          const [stable, unit, multiplier, valueRemainder, valueBase] = data
          /*
            data = [
              3 = unstable, 4 = stable
              3 = kgs, 4 = 12 lbs
              multiplier,
              valueRemainder,
              valueBase
            ]
          */
          const weight = (valueBase * multiplier + valueRemainder) / 100
          onWeightUpdate({
            units: unit === 12 ? 'lb' : 'kg',
            value: weight,
            state: stable === 4 ? 'settled' : 'changing',
          })
        }
      }
    }
  }
  const stopRead = async () => {
    if (scaleDevice.value) {
      try {
        scaleDevice.value.oninputreport = null
        removeScaleDevice()
      } catch (err) {
        console.error('Encountered error closing Scale Device', err)
      }
    }
  }
  return {
    updateScale,
    openScale,
    closeScale,
    removeScaleDevice,
    initiateRead,
    stopRead,
    requestScale,
    connectScale,
    scaleDevice,
    isScaleOpened,
    isScalePaired,
  }
}
