import type { AxiosError, AxiosRequestConfig } from 'axios'

import { appVersion } from '@/appVersion'
import router from '@/router'
import axios from 'axios'
import {
  buildKeyGenerator,
  buildWebStorage,
  setupCache,
} from 'axios-cache-interceptor'

import { useIdentityStore } from '@/stores/identity'

const environment = import.meta.env.VITE_ENVIRONMENT
interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
  retryableStatusList: number[]
}
export const retryableConfig: RetryConfig = {
  retry: 3,
  retryDelay: 250,
  retryableStatusList: [503],
}

export class BlackfiskRequestError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'BlackfiskRequestError'
  }
}

export const createClient = (config: RetryConfig = retryableConfig) => {
  const IdentityStore = useIdentityStore()
  const instance = axios.create({
    baseURL: import.meta.env.VITE_BFSK_API_BASE_URL || '/api',
    timeout: 2 * 60 * 1000, // 2 minutes
    headers: {
      ...(IdentityStore.accountId && {
        'X-Account-Id': IdentityStore.accountId,
      }),
      ...(IdentityStore.userId && { 'X-User-Id': IdentityStore.userId }),
      ...(IdentityStore.token && { Authorization: IdentityStore.token }),
      'Content-Type': 'application/json',
    },
    ...config,
  })
  const client = setupCache(instance, {
    storage: buildWebStorage(localStorage, 'bfsk-axios-cache'),
    methods: ['get'],
    generateKey: buildKeyGenerator((request) => ({
      method: request.method,
      baseURL: request.baseURL,
      data: request.data,
      params: request.params,
      url: request.url,
      accountId: IdentityStore.accountId,
    })),
    ...(environment === 'local' && { debug: console.log }),
  })
  // ! Intercept Request
  client.interceptors.request.use((config) => {
    // * Inject AppVersion
    config.params = config.params || {}
    config.params['_v'] = appVersion
    return config
  })
  // ! Intercept Response
  // * Do not throw on error. Pass through. Replicate transformResponse functionality.
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // const axiosConfig = error.config
      if (error.response) {
        const axiosConfig = error.config as RetryConfig
        const serverResponse = error.response.data as BlackfiskResponse
        // # Server Responded
        // * Handle Unauthorized & Retryable
        if (error?.response?.status === 401) {
          IdentityStore.logout()
          router.push({
            name: 'login',
            query: {
              redirect: router?.currentRoute?.value?.fullPath ?? '/',
            },
          })
          return Promise.reject(
            new BlackfiskRequestError(
              'Please log in to authorize this request.'
            )
          )
        } else {
          console.error(error)
        }
        if (!axiosConfig || !axiosConfig.retry) {
          return Promise.reject(
            new BlackfiskRequestError('Configuration mismatch.')
          )
        }
        if (
          (axiosConfig?.retryableStatusList ?? [503]).includes(
            error?.response?.status
          )
        ) {
          axiosConfig.retry -= 1
          const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve()
            }, axiosConfig.retryDelay || 1000)
          })
          return delayRetryRequest.then(() => client.request(axiosConfig))
        }
        // * Normalize data
        if (serverResponse?.data === null) {
          serverResponse.data = []
        }
        return error.response
      } else if (error.request) {
        // # No Response
        Promise.reject(new BlackfiskRequestError('Server cannot be reached.'))
      } else {
        Promise.reject(new BlackfiskRequestError('Error constructing request.'))
      }
    }
  )
  return client
}
export interface BlackfiskResponse<T = string[]> {
  apiVersion: string
  code: number
  data: T
  error: string[]
  execTimeMS: number
  message: string[]
  timestamp: number
}
export const handleErrors = (error: AxiosError) => {
  const responseData = error?.response?.data as BlackfiskResponse
  if (responseData.error) {
    return responseData.error.map((errorMessage) => ({
      message: errorMessage,
      code: responseData.code,
      reference: null,
    }))
  }
  return [
    {
      message: 'UNKNOWN',
      code: 'An unknown error occurred.',
      reference: null,
    },
  ]
}
