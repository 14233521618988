import { onUnmounted, ref, shallowRef } from 'vue'

export interface AppDrawerSubscription {
  label: string
  target: string
}
const showContextDrawer = ref(false)
const toggleShowContextDrawer = () => {
  showContextDrawer.value = !showContextDrawer.value
}

const subscribers = shallowRef<AppDrawerSubscription[]>([])
const subscribe = (label: string, target: string) => {
  const subscription = {
    label,
    target,
  }
  addSubscriber(subscription)
  // * Automatically remove subscription when component is unmounted
  onUnmounted(() => {
    unsubscribe()
  })
  const unsubscribe = () => {
    removeSubscriber(subscription)
  }
}
const addSubscriber = (subscription: AppDrawerSubscription) => {
  subscribers.value = [...subscribers.value, subscription]
  return subscription
}
const removeSubscriber = (subscriptionToRemove: AppDrawerSubscription) => {
  subscribers.value = subscribers.value.filter((subscription) => {
    return subscription !== subscriptionToRemove
  })
}
export const useAppDrawer = () => {
  return {
    showContextDrawer,
    toggleShowContextDrawer,
    subscribers,
    subscribe,
  }
}
