<script setup lang="ts">
  import type { ToggleSwitchSlots } from 'primevue/toggleswitch'

  import { useId } from 'vue'

  import ToggleSwitch from 'primevue/toggleswitch'

  const slots = defineSlots<ToggleSwitchSlots>()
</script>

<template>
  <ToggleSwitch :id="`input-switch-${useId()}`" v-bind="$attrs">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </ToggleSwitch>
</template>

<style lang="postcss" scoped></style>
