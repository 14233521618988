import type { ScanTypes } from '@/utils/barcode.types'

import { computed, onUnmounted, shallowRef, watch } from 'vue'

import { useScanner } from '@/composables/useScanner'

export type Termination = boolean | 'match'
export interface ListenerOptions {
  priority: number
  termination?: Termination
  terminationWhitelist?: string[]
  inputs?: boolean
}
export interface Listener {
  priority: number
  termination: Termination
  terminationWhitelist: string[]
  bindings: Bindings
}
export type Bindings = Partial<
  Record<
    keyof ScanTypes,
    (scanResult: ScanTypes[keyof ScanTypes]) => boolean | void
  >
>
const listeners = shallowRef<Listener[]>([])
const prioritizedListeners = computed(() => {
  return [...listeners.value].sort(
    (firstListener: Listener, secondListener: Listener) => {
      if (firstListener.priority < secondListener.priority) {
        return 1
      } else if (firstListener.priority > secondListener.priority) {
        return -1
      }
      return 0
    }
  )
})
const listen = (bindings: Bindings, options: ListenerOptions) => {
  const listener = {
    priority: options.priority,
    termination: options?.termination ?? true,
    terminationWhitelist: options?.terminationWhitelist ?? [],
    bindings,
  }
  addListener(listener)
  // * Automatically remove listener when component is unmounted.
  onUnmounted(() => {
    unlisten()
  })
  const unlisten = () => {
    removeListener(listener)
  }
  return unlisten
}
const addListener = (listener: Listener): Listener => {
  listeners.value = [...listeners.value, listener]
  return listener
}
const removeListener = (listenerToRemove: Listener) => {
  listeners.value = listeners.value.filter((listener: Listener) => {
    return listener !== listenerToRemove
  })
}
export const useScanListener = () => {
  const register = () => {
    const { latestScan } = useScanner()
    watch(latestScan, () => {
      if (latestScan.value) {
        for (const listener of prioritizedListeners.value) {
          let result: boolean | void = false
          if (listener.bindings[latestScan.value.type]) {
            result = listener.bindings[latestScan.value.type]?.(
              latestScan.value
            )
          }
          if (result === false) {
            continue
          } else if (result === true) {
            return
          }
          if (listener.termination === 'match') {
            return
          }
          if (
            listener.termination === true &&
            !listener.terminationWhitelist.includes(latestScan.value.type)
          ) {
            return
          }
        }
      }
    })
  }
  return {
    registerListeners: register,
    listeners,
    listen,
  }
}
